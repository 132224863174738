<template>
  <div class="landing-inner-banner" :style="`background: url('${imageDomain}/assets/img/banner_landing.jpg');`">
    <Breadcrumb type="list-area" :listLinkData="linkData" v-if="linkData"/>
    <Banner :banner="bannerImage" v-if="bannerImage !== ''"/>
    <SearchTabs type="tabs"/>
    <!-- <TopBanner :topBanner="topBanner" :topBannerContent="topBannerContent" v-if="topBanner.length > 0" class="mt-4"/> -->
    <!-- <div class="container" v-if="destinationImage">
      <a :href="middleBannerLink">
        <img
          style="width:100%;max-height:300px;"
          :src="destinationImage || `${imageDomain}/assets/img/lesiurpackages_hedimg.jpg`"
          alt="middle-banner"
        />
      </a>
    </div> -->
    <div class="container">
      <div class="row" style="margin: 0;">
        <LandingBannerSlide :dealTypeCode="dealTypeCode" :areaDest="areaDest"/>
        <LandingContact/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bannerImageDestination from '@/utils/bannerImageDestination';
import imageUrlMixin from '@/utils/imageUrlMixin';

const { VUE_APP_LANDING_PAGE_KEY } = process.env;

export default {
  name: 'LandingBanner',
  mixins: [bannerImageDestination, imageUrlMixin],
  components: {
    LandingBannerSlide: () => import('@/components/landing/LandingBannerSlide'),
    LandingContact: () => import('@/components/landing/LandingContact'),
    SearchTabs: () => import('@/components/search/searchTabs/SearchTabsTheme0'),
    Breadcrumb: () => import('@/components/productPage/Breadcrumb'),
    // TopBanner: () => import('@/components/home/TopBanner'),
    Banner: () => import('@/components/productPage/ProductBanner'),
  },
  props: {
    dealTypeCode: String,
    areaDest: String,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      dealType: 'GET_DEAL_TYPE',
      listDestinations: 'GET_LANDING_DESTINATIONS',
      landingInfo: 'GET_LANDING_INFO',
      landingAreaData: 'GET_LANDING_AREA_DATA',
      destinationImages: 'GET_DESTINATION_IMAGES',
    }),
    isAreaType() {
      const { landingInfo } = this;
      if (!landingInfo) return false;
      return landingInfo.areaType || false;
    },
    destCode() {
      return this.$route.query[VUE_APP_LANDING_PAGE_KEY];
    },
    destinationName() {
      const { listDestinations, lang, dealTypeCode, landingAreaData } = this;

      if (!listDestinations || !listDestinations[dealTypeCode]) return '';
      if (this.isAreaType) {
        return landingAreaData?.nameHe || '';
      } else {
        return listDestinations[dealTypeCode].find((item) => item.code === this.destCode)?.name[lang] || '';
      }
    },
    destinationImage() {
      const { destinationImages } = this;
      if (!destinationImages || (destinationImages && !destinationImages.length)) return '';

      const { destination } = this.$route.query;
      const matchedItem = destinationImages.find((d) => d.code === destination);
      return (matchedItem) ? destinationImages.find((d) => d.code === destination).imageUrls[0] : '';
    },
    linkData() {
      const { destinationName, dealType, lang } = this;
      if (!destinationName || !dealType) return null;
      const dealTypeName = dealType.data
        .filter((item) => item.forHomeOrLanding === 'landingpage' || item.forHomeOrLanding === 'both')
        .find((item) => item.code === this.dealTypeCode)?.name[lang] || '';
      return [
        { link: '/', text: this.$t('menu.home') },
        { link: this.$route.fullPath, text: this.$t('landing-page.dest-to-dealType', { dest: destinationName, dealType: dealTypeName }) },
      ];
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
  .landing-inner-banner {
    width: 100%;
    background-size: 100% 100%;
  }
  .breadcrumb-container{
    padding-left: 15px;
    padding-right: 15px;
  }
  .container{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
  /* @media (min-width: 1200px){
    .container {
      max-width: 1140px;
    }
  } */
</style>
<style>
  .landing-inner-banner .breadcrumb_area{
    background: #d0d6e2;
  }
  .landing-inner-banner .breadcrumb_area .arrows li::before, .landing-inner-banner .breadcrumb_area .arrows li::after{
   z-index: 10;
  }
</style>
